@use '../settings/variables/colors';

.page--help {
  min-height: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: colors.$color--pampas;

  .page-title {
    margin: {
      bottom: 20px;
      top: 35px;
    }
  }
}

.layer--index {
  &__1 { z-index: 1 !important; }
  &__2 { z-index: 2 !important; }
  &__3 { z-index: 3 !important; }
  &__5 { z-index: 5 !important; }
  &__10 { z-index: 10 !important; }
  &__custom {
    --z-index: 1;
    z-index: var(--z-index) !important;
  }
}

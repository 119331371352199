@use './assets/sass/init';
@use 'react-big-calendar/lib/sass/styles';
@use './assets/sass/utilities/utilities';
@use './assets/sass/utilities/layer-index';
@use './assets/sass/base/basic';

html {
  scrollbar-width: none;
}
body {
  overflow: hidden;
}

body::webkit-scrollbar {
  width: 0;
  background: transparent;
  display: none;
}

@use '../../../assets/sass/settings/config';
@use '../../../assets/sass/pages/page-popup';
@use '../../../assets/sass/components/dropdown';
@use '../../../assets/sass/components/nav-user';
@use '../../../assets/sass/settings/variables/layout';
@use '../../../assets/sass/settings/variables/colors';
@use '../../../assets/sass/settings/mixins/mixins';


.nav--user {

  &--sidebar {
    @include mixins.flexbox($direction: row, $justify: flex-start, $align: center);
    color: colors.$color--friar-grey;
    gap: 10px;
    height: 100%;
    padding: 0 8px;
    white-space: nowrap;
  }

  .user__avatar {
    @media #{layout.$breakpoint-m-max} {
      height: 40px;
      width: 40px;
    }

    img:focus,
    img.focus {
      outline: none !important;
    }
  }
}

@use './assets/sass/pages/page-form';
@use './assets/sass/pages/page-help';
@use './assets/sass/pages/page-popup';
@use './assets/sass/pages/page-requests';
@use './assets/sass/global/content';
@use './assets/sass/components/buttons';
@use './assets/sass/components/page-title';
@use './assets/sass/components/form';
@use './assets/sass/components/links';
@use './assets/sass/settings/mixins/mixins';
@use './assets/sass/settings/variables/colors';

body {
  height: 100vh;
  overflow: hidden;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.page {
  @include mixins.flexbox($direction: column);
  height: 100vh;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  &:before {
    background-color: rgba(203, 201, 196, 0.4);
    bottom: 0;
    content: '';
    left: 100%;
    position: absolute;
    right: -100%;
    top: 0;
    z-index: 10;
  }

  &:after {
    background-color: rgba(55, 53, 48, 0.8);
    bottom: 0;
    content: '';
    left: 100%;
    position: absolute;
    right: -100%;
    top: 0;
    transition: all 0.3s ease;
    z-index: 10;
  }

  &.overlay:before,
  &.overlay-secondary:after {
    left: 0;
    right: 0;
  }

  &.overlay-secondary:after {
    transition: none;
  }
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

@use '../../assets/sass/global/validation-errors';
@use '../../assets/sass/settings/variables/colors';
@use '../../assets/sass/settings/variables/layout';
@use '../../assets/sass/settings/mixins/mixins';

.page--auth {
  height: 100%;
  background-color: colors.$color--pampas;

  @media #{layout.$breakpoint-m-max} {
    background-color: colors.$color--white;
  }

  .logo {
    margin-bottom: 100px;
  }

  .form {
    margin-top: 20px;
  }

  .form__links {

    @media #{layout.$breakpoint-m-max} {
      margin-top: 20px;
    }

    @media #{layout.$breakpoint-l} {
      margin-top: 40px;
    }

    p {
      color: colors.$color--dove-gray;

      @media #{layout.$breakpoint-m-max} {
        font-size: 14px;
      }
    }
  }

  img {
    height: auto;
    max-width: 100%;
  }
}

.form--login {
  .login__button {
    width: 389px;
    height: 45px;
    padding: 0 !important;
    @include mixins.flexbox (
      $align: center,
      $justify: center,
      $wrap: nowrap
    );

    @media #{layout.$breakpoint-s} {
      flex: 1;
    }
  }
}

.region--content--page-auth {
  @media #{layout.$breakpoint-m-max} {
    @include mixins.flexbox ($justify: center);
  }
}

@use '../settings/variables/colors';
@use '../settings/mixins/mixins';
@use '../settings/variables/typography';

.link {
  @include mixins.fontStyle ($fontWeight: typography.$fw--bold, $fontColor: colors.$color--radical-red);
  text-decoration: none;
  transition: all .1s ease;

  &--dark {
    @include mixins.fontStyle ($fontColor: colors.$color--dove-gray);
  }

  &--medium {
    @include mixins.fontStyle ($fontWeight: typography.$fw--medium);
  }

  &--font{
   &--med {
    @include mixins.fontStyle ($fontSize: 13px);
    }
  }

  &--pointer {
    cursor: pointer;
  }
}
@use '../settings/mixins/mixins';
@use '../settings/variables/layout';
@use '../settings/variables/colors';
@use '../settings/variables/icons';
@use '../settings/variables/typography';

.nav--main {
  @media #{layout.$breakpoint-l} {
    margin-right: 1.5%;
  }

  @media #{layout.$breakpoint-m-max} {
    background-color: colors.$color--white;
    height: 100vh;
    padding: 22px 15px 30px;
    position: absolute;
    right: -40%;
    top: 0;
    transition: all 0.3s ease;
    width: 40%;
    z-index: 6;

    &.open {
      right: 0;
    }
  }

  @media #{layout.$breakpoint-s} {
    right: -60%;
    width: 60%;
  }

  .nav__list {
    @include mixins.flexbox ();

    @media #{layout.$breakpoint-l} {
      column-gap: 52px;
    }

    @media #{layout.$breakpoint-m-max} {
      @include mixins.flexbox ($direction: column);
      height: 100%;
    }

    @media #{layout.$breakpoint-l} {
      @include mixins.flexbox ($align: center);
    }
  }

  .nav__item {
    &:not(:last-of-type) {
      @media #{layout.$breakpoint-m-max} {
        margin-bottom: 12px;
      }
    }

    &.border-top {
      padding-top: 25px;
    }

    &--sign-out {
      @media #{layout.$breakpoint-m-max} {
        margin-top: auto;

        .nav__link {
          background: {
            image: url(icons.$icon--sign-out);
            position: left bottom 2px;
            repeat: no-repeat;
          }
          padding-left: 30px;
        }
      }

      @media #{layout.$breakpoint-l} {
        display: none;
      }
    }
  }

  .nav__link {
    font-weight: typography.$fw--medium;
    text-decoration: none;
    transition: all 0.1s ease;
    color: colors.$color--dune;

    &.is-active {
      color: colors.$color--radical-red;
    }

    @media #{layout.$breakpoint-l} {
      position: relative;
      color: colors.$color--friar-grey;

      &:hover {
        color: colors.$color--dune;
      }

      &.is-active {
        color: colors.$color--dune;
        border: none;

        &:after {
          height: 3px;
        }
      }

      &:after {
        background-color: colors.$color--radical-red;
        bottom: -19px;
        content: '';
        height: 0;
        left: 0;
        position: absolute;
        right: 0;
      }
    }
  }
}

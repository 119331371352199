@use '../variables/colors';

@mixin flexbox(
    $justify: null, 
    $align: null, 
    $direction: null, 
    $wrap: null, 
    $order: null,
    $grow: null, 
    $shrink: null, 
    $basis: null,
    ) {
        display: flex;
        justify-content: $justify;
        align-items: $align;
        flex-direction: $direction;
        flex-wrap: $wrap;
        order: $order;
        flex: $grow $shrink $basis
};

@mixin fontStyle(
    $fontSize: null,
    $fontWeight: null,
    $fontStretch: normal,
    $fontStyle: normal,
    $lineHeight: null,
    $letterSpacing: null,
    $fontColor: null,
    
) {
    font-size: $fontSize;
    font-weight: $fontWeight;
    font-stretch: $fontStretch;
    font-style: $fontStyle;
    line-height: $lineHeight;
    letter-spacing: $letterSpacing;
    color: $fontColor;
}

 @mixin border($side: null) {
    @if $side != null {
        border-#{$side}: 1px solid colors.$color--cararra;
    } @else {
        border: 1px solid colors.$color--cararra;
    }
 }

@use '../settings/mixins/mixins';
@use '../settings/variables/layout';
@use '../settings/variables/colors';
@use '../settings/variables/typography';
@use '../settings/variables/icons';

* {
  box-sizing: border-box;
}

html,
body {
  max-width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

html {
  &.no-scroll {
    overflow: hidden;
  }
}

body {
  @include mixins.fontStyle($fontSize: typography.$fs--root);
  font-family: typography.$font-primary;
  margin: 0;
  position: relative;

  &.page--form,
  &.page--settings,
  &.page--help {
    background-color: colors.$color--pampas;
  }

  &:before {
    background-color: rgba(colors.$color--cloud, 0.4);
    bottom: 0;
    content: '';
    left: 100%;
    position: absolute;
    right: -100%;
    top: 0;
    transition: all 0.3s ease;
    z-index: 5;
  }

  &:after {
    background-color: rgba(colors.$color--dune, 0.8);
    bottom: 0;
    content: '';
    left: 100%;
    position: absolute;
    right: -100%;
    top: 0;
    transition: all 0.3s ease;
    z-index: 10;
  }

  &.overlay:before,
  &.overlay-secondary:after {
    left: 0;
    right: 0;
  }

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}

figure {
  margin: 0;
}

// Lists.
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

// Paragraphs.
p {
  margin: {
    top: 0;
    bottom: 0;
  }

  &.border-bottom {
    padding-bottom: 20px;
    margin-bottom: 20px;

    &__notes {
      margin: 0;
    }
  }

  &.border-top {
    padding-top: 20px;
    margin-top: 20px;
  }
}

.p {
  &--small {
    @include mixins.fontStyle($fontSize: 11px, $fontWeight: typography.$fw--medium, $lineHeight: 28px, $fontColor: colors.$color--friar-grey);
    text-transform: uppercase;
  }

  &--med {
    @include mixins.fontStyle($fontSize: 13px, $lineHeight: 15px);
  }

  &--big {
    @include mixins.fontStyle($fontSize: 14px, $lineHeight: 16px);
  }

  &--desc {
    @include mixins.fontStyle($fontSize: 14px, $lineHeight: 24px, $fontColor: colors.$color--friar-grey);
    margin-top: 8px;
  }

  &--show-more {
    @include mixins.fontStyle($fontSize: 13px, $fontWeight: typography.$fw--bold, $fontColor: colors.$color--radical-red);
    background: {
      image: url(icons.$icon--chevron--red);
      position: right center;
      repeat: no-repeat;
    }
    cursor: pointer;
    display: inline-flex;
    padding-right: 18px;
    text-transform: uppercase;
  }
}

// Blockquote.
blockquote {
  margin: 0;
}

// Default typography properties.
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: {
    top: 0;
    bottom: 0;
  }
}

h2 {
  @include mixins.fontStyle($fontSize: 18px, $fontWeight: typography.$fw--normal, $lineHeight: 21px, $fontColor: colors.$color--dune);

  &.border-bottom {
    margin-bottom: 22px;
    padding-bottom: 24px;
  }

  &.border-top {
    padding-top: 15px;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

h3 {
  @include mixins.fontStyle($fontSize: 16px, $fontWeight: typography.$fw--medium, $lineHeight: 20px, $fontColor: colors.$color--black);
}

h4 {
  @include mixins.fontStyle($fontSize: 16px, $fontWeight: typography.$fw--normal, $lineHeight: 20px, $fontColor: colors.$color--black);
}

.link--absolute {
  font-size: 0;
  position: absolute;
  inset: 0;
  z-index: 1;

  &:focus {
    outline: none;
  }
}

img {
  display: block;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    border: none;
  }
}

.border-full {
  @media #{layout.$breakpoint-m-max} {
    margin: {
      left: -15px;
      right: -15px;
    }
    padding: {
      left: 15px;
      right: 15px;
    }
  }
}

.border-distance {
  margin-top: 140px !important;
}

.border-bottom {
  @include mixins.border($side: bottom);
}

.border-top {
  @include mixins.border($side: top);
}

.border {
  @include mixins.border();
}

.hide-mobile {
  @media #{layout.$breakpoint-m-max} {
    display: none !important;
  }
}

.hide-desktop {
  @media #{layout.$breakpoint-l} {
    display: none !important;
  }
}

.hide-small {
  @media #{layout.$breakpoint-s} {
    display: none;
  }
}

.hide-large {
  @media #{layout.$breakpoint-m-min} {
    display: none;
  }
}

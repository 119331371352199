@use '../settings/variables/layout';
@use '../settings/mixins/mixins';
@use '../settings/variables/typography';

.form--invitation {

  @media #{layout.$breakpoint-m-max} {
    @include mixins.flexbox ($direction: column);
  }

  .fieldset--flex {
    position: relative;

    &:first-of-type {
      .form__item--remove {
        @media #{layout.$breakpoint-l} {
          margin-top: 20px;
        }
      }
    }

    &:not(:first-of-type) {

      .form__item label:first-of-type {
        @media #{layout.$breakpoint-l} {
          display: none;
        }
      }
    }
  }

  .form__item {

    &:not(:last-of-type) {
      @media #{layout.$breakpoint-l} {
        margin-bottom: 0;
      }
    }

    label {
      font-size: 11px;
      text-transform: uppercase;
    }
    
    &--email,
    &--text {
      @media #{layout.$breakpoint-m-max} {
        width: 100%;
      }

      @media #{layout.$breakpoint-l} {
        flex: 0 1 300px;
      }
    }

    &--email {
      @media #{layout.$breakpoint-l} {
        margin-right: 20px;
      }
    }

    &--text {
      @media #{layout.$breakpoint-l} {
        margin-right: 10px;
      }
    }

    &--remove {
      flex: 0 0 34px;

      @media #{layout.$breakpoint-m-max} {
        position: absolute;
        right: 6px;
        top: 26px;
      }

      @media #{layout.$breakpoint-l} {
        margin-right: 25px;
      }
    }

    &--checkbox {

      @media #{layout.$breakpoint-m-max} {
        position: absolute;
        right: 0;
        top: -6px;
      }

      @media #{layout.$breakpoint-l} {
        flex: 0 0 86px;
        margin-right: 40px;
      }

      label:first-of-type {

        @media #{layout.$breakpoint-m-max} {
          display: none;
        }

        @media #{layout.$breakpoint-l} {
          font-weight: typography.$fw--medium;
          display: block;
          margin-bottom: 8px;
          white-space: nowrap;
        }
      }

      input + label {
        background-position: left center;

        @media #{layout.$breakpoint-m-max} {
          font-weight: typography.$fw--medium;
        }

        @media #{layout.$breakpoint-l} {
          font-size: 0;
          line-height: 45px;
        }
      }
    }
  }

  & > .button {
    order: -1;

    @media #{layout.$breakpoint-m-max} {
      margin-left: auto;
    }
  }
}
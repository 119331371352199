@use '../../../assets/sass/global/header';
@use '../../../assets/sass/settings/variables/layout';
@use '../../../assets/sass/settings/mixins/mixins';
@use '../../../assets/sass/components/form-search';

.app--header {
  z-index: 8;

  @media #{layout.$breakpoint-m-max} {
    z-index: 10;
  }
}

.region--header .header--primary {
  z-index: 1;
}

.header--total-items {
  @include mixins.flexbox($align: center);
}

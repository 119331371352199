@use '../settings/mixins/mixins';
@use '../settings/variables/layout';
@use '../settings/variables/colors';
@use '../settings/variables/typography';

.page-title {
  @include mixins.fontStyle ($fontSize: 24px, $lineHeight: 28px, $fontColor: colors.$color--black);

  &.border-bottom {
    @media #{layout.$breakpoint-m-max} {
      padding-bottom: 15px;
      margin-bottom: 13px;
    }

    @media #{layout.$breakpoint-l} {
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  }

  &--centered {
    text-align: center;
  }

  &__span {
    @include mixins.fontStyle ($fontSize: 13px, $fontWeight: typography.$fw--medium, $fontColor: colors.$color--gray);
  }
}
@use '../settings/variables/colors';
@use '../settings/variables/layout';
@use '../settings/mixins/mixins';
@use '../settings/variables/icons';
@use '../settings/variables/typography';
@use './form-invitation';

.form {
  &.border-bottom {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

p.form-error {
  @include mixins.fontStyle ($fontSize: 13px, $fontColor: colors.$color--maroon-flush);
  margin-top: 10px;
}

p.form-error::before {
  display: inline;
  content: '⚠';
}

.required {
  position: relative;
  display: inline-block!important;

  &:before {
    content: "";
    position: absolute;
    display: block;
    z-index: 2;
    top: -2px;
    left: auto;
    right: -6px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: colors.$color--radical-red;
  }
}

.form__item {
  &:not(:last-of-type) {
    margin-bottom: 25px;
  }

  label {
    @include mixins.fontStyle ($fontSize: 13px, $fontColor: colors.$color--friar-grey);

    a {
      @include mixins.fontStyle ($fontWeight: typography.$fw--bold, $fontColor: inherit);
      text-decoration: none;
    }
  }

  &.form__item--text,
  &.form__item--email,
  &.form__item--password,
  &.form__item--tel,
  &.form__item--select {
    & > label {
      display: block;
      font-weight: typography.$fw--medium;
      margin-bottom: 8px;
    }
  }

  &.form__item--checkbox {
    .font-weight--normal {
      @include mixins.fontStyle ($fontWeight: typography.$fw--normal, $lineHeight: 20px);
    }
    & > label {
      @include mixins.fontStyle ($fontWeight: typography.$fw--medium, $lineHeight: 20px);
      display: inline-block;
    }
  }
  &.form__item--checkbox,
  &.form__item--radio {
    &:not(:last-of-type) {
      margin-bottom: 5px;
    }
  }


  .form--select--disable {
    pointer-events: none;
    background-color: colors.$color--cararra;
    border-color: colors.$color--cloud;
    color: colors.$color--friar-grey;
  }

  &.form__item--select {
    .form--select-label {
      margin-bottom: 0;
    }
  }

  &.border-bottom {
    padding-bottom: 20px;
  }

  &.border-top {
    padding-top: 20px;
  }

  &-footer {
    @include mixins.flexbox ($align: center, $justify: space-between);
    margin-top: 20px;

    a {
      font-size: 13px;
    }
  }

  &.required {
    position: relative;
    display: block;

    &:before {
      content: "";
      position: absolute;
      display: block;
      z-index: 2;
      top: 4px;
      left: 4px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: colors.$color--radical-red;
    }
  }

  &.add-input-margin {
    margin-bottom: 25px;
  }
}

input[type=file],
input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}

input,
textarea {
  font-family: typography.$font-primary;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
textarea {
  color: colors.$color--dune;
  width: 100%;

  &:focus {
    color: colors.$color--dune;
    outline: none;
  }
}

.form--text,
.form--email,
.form--password,
.form--tel,
.form--textarea {
  background-color: colors.$color--white;
  border: 1px solid colors.$color--cloud;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 colors.$color--black-russian;
  font-size: 14px;
  padding: {
    left: 15px;
    right: 15px;
  }

  @media #{layout.$breakpoint-l} {
    &:hover {
      background-color: colors.$color--pampas;
      border-color: colors.$color--friar-grey;
    }
  }

  &:focus {
    border-color: colors.$color--radical-red;
  }

  &:disabled {
    background-color: colors.$color--cararra;
    border-color: colors.$color--cloud;
    color: colors.$color--friar-grey;
  }
}

.form--text,
.form--email,
.form--password,
.form--tel {
  line-height: 43px;
  padding: {
    bottom: 0;
    top: 0;
  }
}

.form--textarea {
  padding: {
    bottom: 15px;
    top: 15px;
  }
  resize: none;
}

.form--date {
  @include mixins.fontStyle ($fontSize: 14px, $lineHeight: 34px, $fontColor: colors.$color--dune);
  background-color: colors.$color--white;
  border: 1px solid colors.$color--cloud;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 colors.$color--black-russian;
  padding: {
    left: 15px;
    right: 15px;
  }

  @media #{layout.$breakpoint-l} {
    &:hover {
      background-color: colors.$color--pampas;
      border-color: colors.$color--friar-grey;
    }
  }

  &:focus {
    border-color: colors.$color--radical-red;
    color: colors.$color--dune;
    outline: none;
  }

  &:disabled {
    background-color: colors.$color--cararra;
    border-color: colors.$color--cloud;
    color: colors.$color--friar-grey;
  }
}

.form--select {
  @include mixins.fontStyle ($fontSize: 14px, $fontColor: colors.$color--dune);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: {
    color: colors.$color--white;
    image: url(icons.$icon--select-list);
    position: right 16px center;
    repeat: no-repeat;
  }
  border: {
    color: colors.$color--cloud;
    radius: 2px;
  }
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  height: 45px;
  padding: {
    left: 15px;
    right: 15px;
  }
  width: 100%;

  @media #{layout.$breakpoint-l} {
    &:hover {
      background-color: colors.$color--pampas;
      border-color: colors.$color--friar-grey;
    }
  }

  &:focus {
    border-color: colors.$color--radical-red;
    color: colors.$color--dune;
    outline: none;
  }

  &:disabled {
    background-color: colors.$color--cararra;
    border-color: colors.$color--cloud;
    color: colors.$color--friar-grey;
  }

  &__padding-right {
    padding-right: 30px;
  }
}

.radio-buttons-wrapper > label {
  @include mixins.fontStyle ($fontSize: 13px, $fontWeight: typography.$fw--medium, $fontColor: colors.$color--friar-grey);
}

.radio-buttons {
  @include mixins.flexbox ();
  margin-top: 8px;

  .form__item--radio {
    flex: 1;
    text-align: center;

    &:first-of-type label {
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
    }

    &:last-of-type label {
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
    }
  }
}

.form--checkbox,
.form--radio,
.form--radio-button {
  display: none;
}

.form--checkbox + label,
.form--radio + label {
  background-repeat: no-repeat;
  cursor: pointer;
  display: inline-block;
  line-height: 20px;
  padding-left: 30px;
  transition: all 0.1s ease;
}

.form--checkbox:not(:checked) + label {
  background-image: url(icons.$icon--checkbox-default);

  @media #{layout.$breakpoint-l} {
    &:hover {
      background-image: url(icons.$icon--checkbox-hover);
    }
  }
}

.form--checkbox:not(:checked):disabled + label {
  &:hover {
    background-image: url(icons.$icon--checkbox-default);
  }
}

.form--checkbox:disabled + label {
  cursor: default;
}

.form--checkbox:checked + label {
  background-image: url(icons.$icon--checkbox-active);
}

.form--radio:not(:checked) + label {
  background-image: url(icons.$icon--radio-default);

  @media #{layout.$breakpoint-l} {
    &:hover {
      background-image: url(icons.$icon--radio-hover);
    }
  }
}

.form--radio:checked + label {
  background-image: url(icons.$icon--radio-active);
}

.form--radio-button {
  & + label {
    border: 1px solid;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
    cursor: pointer;
    display: block;
  }

  &--small + label {
    @include mixins.fontStyle ($lineHeight: 28px);
  }

  &--big + label {
    @include mixins.fontStyle ($fontSize: 14px, $lineHeight: 43px);
  }
}

.form--radio-button:not(:checked) + label {
  border-color: colors.$color--friar-grey;
}

.form--radio-button:checked + label {
  border-color: colors.$color--radical-red;
  color: colors.$color--radical-red;
}

.form__item--profile-photo {
  @include mixins.flexbox ($align: center);

  @media #{layout.$breakpoint-m-max} {
    @include mixins.flexbox ($direction: column, $justify: center);
  }

  @media #{layout.$breakpoint-l} {
    padding-right: 20px;
  }

  .background-profile-photo {
    background: {
      image: url(icons.$icon--upload);
      position: center center;
      repeat: no-repeat;
    }
  }

  .image-container {
    width: 61px;
    height: 61px;

    span {
      display: block;
      width: 60px;
      height: 60px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .circular--square {
    border-radius: 50%;
    height: 0;
    width: 0;
  }

  .circular {
    border-radius: 50%;
  }

  & > label {
    border: 1px solid colors.$color--gallery;
    border-radius: 50%;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
    cursor: pointer;
    font-size: 0;
    height: 60px;

    @media #{layout.$breakpoint-m-max} {
      margin-bottom: 15px;
      width: 60px;
    }

    @media #{layout.$breakpoint-l} {
      flex: 0 0 60px;
      margin-right: 20px;
    }
  }

  .form__item-help {
    @include mixins.flexbox ($direction: column);

    @media #{layout.$breakpoint-m-max} {
      @include mixins.flexbox ($align: center);
    }

    @media #{layout.$breakpoint-l} {
      @include mixins.flexbox ($align: flex-start);
    }

    label {
      @include mixins.fontStyle ($fontSize: 13px, $fontWeight: typography.$fw--medium, $fontColor: colors.$color--dune);
      margin-bottom: 2px;
    }
  }
}

.form__item--logo {
  & > label {
    @include mixins.fontStyle ($fontSize: 14px, $fontWeight: typography.$fw--bold);
    @include mixins.border();
    border-radius: 4px;
    background: {
      image: url(icons.$icon--upload);
      position: left 11px center;
      repeat: no-repeat;
      size: 18px 13px;
    }
    cursor: pointer;
    padding: 8px 15px 9px 40px;
  }
}

.form__item--drag-drop {
  background: {
    image: url('../../images/drag-and-drop.svg');
    position: right center;
    repeat: no-repeat;
  }
  border: 1px dashed colors.$color--cararra;
  border-radius: 3px;
  padding: {
    bottom: 45px;
    top: 50px;
  }
  position: relative;
  transition: all 0.2s ease;
  width: 100%;

  &:focus {
    border-color: colors.$color--radical-red;
    outline: none;
  }

  @media #{layout.$breakpoint-s} {
    background-size: 30%;
    padding: {
      left: 20px;
      right: 35%;
    }
  }

  @media #{layout.$breakpoint-l} {
    padding: {
      left: 40px;
      right: 130px;
    }

    &:hover,
    &.isDraggingOver {
      background-color: colors.$color--pampas;
      border-color: colors.$color--radical-red;
    }
  }

  p {
    color: colors.$color--dusty-gray;
    margin-top: 8px;
  }

  label {
    bottom: 0;
    cursor: pointer;
    font-size: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.form--file {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.form--image {
  border: 1px solid transparent;

  background: {
    image: url(icons.$icon--editor--attach);
    position: center center;
    repeat: no-repeat;
  }
  input {
    opacity: 0;
    width: 28px;
    height: 28px;
  }
  label {
    cursor: pointer;
    pointer-events: auto;
  }

  &:hover {
    border: 1px solid #e8e7e4;
    border-radius: 4px;
  }
}

.fieldset {
  margin: {
    bottom: 20px;
    top: 20px;
  }

  &.border-bottom {
    padding-bottom: 20px;
  }

  & > label {
    @include mixins.fontStyle ($fontSize: 13px, $fontColor: colors.$color--friar-grey);
  }

  &:not(.fieldset--flex) {
    .form__item:first-of-type {
      margin-top: 12px;
    }
  }

  &--flex {
    @include mixins.flexbox ($align: center);

    @media #{layout.$breakpoint-m-max} {
      @include mixins.flexbox ($direction: column);
    }
  }

  &--margin {
    margin-bottom: 30px;
  }
}

.form__item--remove {
  background: {
    image: url(icons.$icon--cancel);
    position: center center;
    repeat: no-repeat;
  }
  border-radius: 4px;
  cursor: pointer;
  font-size: 0;
  height: 34px;
  transition: all 0.1s ease;
  width: 34px;

  &:not(:hover) {
    background-color: colors.$color--pampas;
  }

  @media #{layout.$breakpoint-l} {
    &:hover {
      background-color: colors.$color--quill-gray;
    }
  }
}

.form__item--switch {
  label {
    @include mixins.flexbox ($align: center);
    @include mixins.fontStyle ($fontSize: 14px, $lineHeight: 24px, $fontColor: colors.$color--black);
  }

  .form__items--column {
    @include mixins.flexbox ($direction: column, $align: initial);
    font-size: 16px;

    & > * {
      padding-top: 10px;
      padding-left: 15px;

      &:last-of-type {
        border: none;
        padding-bottom: 0;
      }
    }
  }
}

.form--switch {
  flex: 0 0 40px;
  height: 24px;
  margin-left: auto;
  position: relative;
  width: 40px;

  .field-input & {
    margin-left: 8px;
  }

  input {
    opacity: 1;
    width: 10;
    height: 10;

    &:not(:checked) {
      & + .slider {
        background-color: colors.$color--friar-grey;

        &:before {
          left: 2px;
        }
      }
    }

    &:checked {
      & + .slider {
        background-color: colors.$color--radical-red;

        &:before {
          transform: translateX(18px);
        }
      }
    }
  }

  .slider {
    border-radius: 17px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.2s ease;

    &:before {
      background-color: colors.$color--white;
      border-radius: 50%;
      bottom: 2px;
      content: '';
      height: 20px;
      position: absolute;
      transition: all 0.2s ease;
      width: 20px;
    }
  }
}

.form__actions {
  @include mixins.flexbox ($justify: flex-end);
  margin-top: 20px;

  .button {
    @media #{layout.$breakpoint-s} {
      flex: 0 1 50%;
      justify-content: center;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &.border-top {
    padding-top: 20px;
  }
}

.form--submit {
  text-align: center;

  .form--forgot-password &,
  .form--first-login & {
    flex: 0 0 100%;
  }

  .form--forgot-password & {
    text-align: left;
  }
}

.form__help {
  @include mixins.fontStyle ($fontSize: 14px, $lineHeight: 20px, $fontColor: colors.$color--friar-grey);

  @media #{layout.$breakpoint-m-max} {
    text-align: center;
  }
}

.form-time-checkbox {
  font-size: 14px;

  @media #{layout.$breakpoint-s} {
   margin-bottom: 20px;
  }
}

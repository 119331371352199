@use '../settings/mixins/mixins';
@use '../settings/variables/layout';
@use '../settings/variables/colors';

.page--popup {
  padding: {
    left: 15px;
    right: 15px;
    top: 70px;
  }

  @media #{layout.$breakpoint-m-max} {
    padding-bottom: 15px;
  }

  @media #{layout.$breakpoint-l} {
    padding-bottom: 70px;
  }

  .region--content {
    margin: {
      left: auto;
      right: auto;
    }

    @media #{layout.$breakpoint-m-max} {
      width: 100%;
    }
  }

  .region--content--center {
    @include mixins.flexbox ($align: center, $direction: column);
  }

  .button--close {
    background-color: colors.$color--pampas;
    border-color: colors.$color--pampas;
    position: absolute;

    @media #{layout.$breakpoint-m-max} {
      right: 15px;
      top: 15px;
    }

    @media #{layout.$breakpoint-l} {
      background-size: 20px;
      right: 50px;
      top: 50px;
    }
  }

  .request-types {
    margin-top: 45px;
  }

  .request-type {
    border-radius: 4px;
    cursor: pointer;
    padding: 14px 20px;
    position: relative;
    transition: all .1s ease;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    h2 {
      color: colors.$color--dune;
      margin-bottom: 4px;
    }

    p {
      color: colors.$color--friar-grey;
    }

    @media #{layout.$breakpoint-l} {
      &:hover {
        background-color: colors.$color--radical-red;
        border-color: colors.$color--radical-red;

        h2,
        p {
          color: colors.$color--white;
        }
      }
    }
  }
}

.page--request-landing {
  .region--content {
    @media #{layout.$breakpoint-l} {
      width: 500px;
    }
  }
}

.page--request {
  .region--content {
    @media #{layout.$breakpoint-l} {
      width: 660px;
    }
  }

  .page-title {
    width: 100%;
  }
}

@use "../settings/mixins/mixins";
@use "../settings/variables/layout";
@use "../settings/variables/colors";
@use "../settings/variables/typography";
@use "../settings/variables/icons";

.region--header {
  position: relative;
  transition: 0.3s all ease-in-out;

  .region__inner {
    @include mixins.flexbox ($align: center, $wrap: wrap);

    .dropdown {
      @media #{layout.$breakpoint-m-max} {
        .button--dropdown{
          width: 100%;
        }
      }
    }
    background-color: colors.$color--white;
    border-bottom: {
      color: colors.$color--quill-gray;
      style: solid;
    }

    @media #{layout.$breakpoint-m-max} {
      padding: {
        left: 15px;
        right: 15px;
      }
    }

    @media #{layout.$breakpoint-l} {
      padding: {
        left: 20px;
        right: 20px;
      }
      position: relative;
    }
  }

  .header__mobile-row {
    @include mixins.flexbox($justify: center);
    position: relative;

    @media #{layout.$breakpoint-m-max} {
      @include mixins.border ($side: top);
      flex: 0 0 100vw;
      margin: {
        left: -15px;
        right: -15px;
        top: 13px;
      }
      order: 2;
      padding: {
        left: 15px;
        right: 15px;
        top: 13px;
      }

      .form__item--search {
        order: -1;
      }
    }

    @media #{layout.$breakpoint-l} {
      flex: 1;
    }

    @media #{layout.$breakpoint-m-max} {
      @include mixins.flexbox($justify: space-between);
    }
  }

  .header--primary {
    position: relative;
    border-width: 2px;
    z-index: 3;
    padding: 9px 20px;
    border-bottom-color: colors.$color--quill-gray;
    border-bottom-style: solid;
    transition: margin-left 0.5s ease-in-out;
  }

  .region__inner--primary {
    @include mixins.flexbox($direction: row, $justify: space-between, $align: center);
  }

  .trigger--menu-open {
    @media #{layout.$breakpoint-m-max} {
      background: {
        image: url(icons.$icon--menu-open);
        repeat: no-repeat;
        size: 100%;
      }
      font-size: 0;
      height: 15px;
      margin: {
        left: auto;
        right: 20px;
      }
      width: 15px;
    }
  }

  .header--secondary {
   @media #{layout.$breakpoint-l} {
     width: 200px;
     height: 100%;
     position: fixed;
     border: 1px;
     background-color: colors.$color--white;
     top: 0;
     z-index: 20;
     transition: width 0.5s ease-in-out;

     &.collapsed {
       width: 80px;
     }
   }
  }

  .region__inner--secondary {
    @include mixins.flexbox($direction: column, $justify: space-between);
    margin: 0 15px;
    height: 100%;

    .sidebar--item {
      width: 165px;
      height: 45px;
      border-radius: 8px;
      background: colors.$color--pristine-ivory;
      color: colors.$color--friar-grey;
      text-decoration: none;
      margin-top: 5px;
      transition: width 0.5s ease-in-out;
      opacity: 1;

      @media #{layout.$breakpoint-s} {
        width: 100%;
      }

      &--requests {
        height: auto;
      }

      &.collapse {
        width: 48px;
      }

      &--active {
        background-color: colors.$color--radical-red;
        color: colors.$color--white;
      }
    }

    .sidebar--item p {
      opacity: 1;
      transition: opacity 0.8s ease-in-out;
    }

    .sidebar--item.collapse p {
      opacity: 0;
      transition: opacity 0.4s ease-in-out;
    }

    .sidebar--link {
      @include mixins.flexbox($justify: left);
      color: colors.$color--friar-grey;
      text-decoration: none;
      gap: 12px;
      padding: 12px 16px;

      &.collapsed {
        width: 48px;

        &:hover {
          svg path {
            stroke: colors.$color--raspberry-red;
          }
        }

        &.sidebar--link--requests :not(.requests-icon-active) {
          &:hover {
            svg path {
              fill:  colors.$color--raspberry-red;
              stroke: colors.$color--raspberry-red;
            }
          }
        }
      }
    }

    .sidebar--item--active .sidebar--link {
      color: colors.$color--white;

      .requests-icon-active {
        svg path {
          fill: colors.$color--white;
          stroke: colors.$color--white;
          stroke-width: 0px;
        }
      }

      :not(.requests-icon-active) {
        svg path {
          stroke: colors.$color--white;
        }
      }
    }
  }

  .header__title {
    @include mixins.fontStyle ($fontSize: 20px, $fontWeight: typography.$fw--bold, $fontColor: colors.$color--black);
    margin-right: 10px;
  }

  .header__text {
    color: colors.$color--dusty-gray;
    margin: {
      right: 10px;
      top: 3px;
    }
  }

  .button--filters {
    margin-right: 10px;
  }

  .additional-filters {
    @include mixins.flexbox($direction: column);
    gap: 8px;
    padding: 0 10px 22px 24px;
  }

  .additional-filters__item {
    @include mixins.fontStyle ($fontSize: 13px, $fontWeight: typography.$fw--bold, $fontColor: colors.$color--white);
    border-radius: 4px;
    padding: 10px;
    transition: all 0.3s ease;
    text-decoration: none;

    &.is-active {
      background-color: colors.$color--coral-pink;
      color: colors.$color--white;
    }
  }

  .additional-item-container {
    @media #{layout.$breakpoint-s} {
      @include mixins.flexbox($justify: flex-start);
      gap: 8px;
    }
  }

  .dropdown--sort {
    margin-left: auto;
  }

  .header--tertiary {
    border-width: 0;
    left: 0;
    padding: {
      bottom: 15px;
      top: 15px;
    }
    position: absolute;
    right: 0;
    transition: all 0.3s ease-in-out;
    z-index: -1;

    @media #{layout.$breakpoint-s} {
      @include mixins.flexbox ($align: flex-start, $direction: column);
      top: -400%;
    }

    @media #{layout.$breakpoint-m-min} {
      top: -200%;
    }

    &.open {
      top: 100%;
      @media #{layout.$breakpoint-s} {
        height: 100vh;
      }
    }

    .dropdown {
      @media #{layout.$breakpoint-s} {
        margin-bottom: 18px;
      }

      @media #{layout.$breakpoint-m-min} {
        margin-right: 10px;
      }
    }

    .filter-footer {
      @include mixins.flexbox ($align: center, $justify: stretch);

      .form__item--search {
        margin-right: 15px;
      }
      @media #{layout.$breakpoint-s} {
        @include mixins.border ($side: top);
        @include mixins.flexbox ($align: center, $justify: space-between);
        margin-left: -15px;
        padding: {
          left: 0;
          right: 15px;
          top: 15px;
        }
        width: 100vw;
      }

      @media #{layout.$breakpoint-m-min} {
        margin-left: auto;
      }

      @media (max-width: layout.$header-width-m-max) {
        .form__item--search {
          margin-right: 10px;
        }
      }
    }

    .button--reset {
      @media #{layout.$breakpoint-s} {
        margin-right: auto;
      }
    }
  }
}

.header--group {
  @include mixins.flexbox ($align: center);
}

.nav__list--secondary {
  display: flex;
  flex-direction: column;
}

.sidebar--logo {
  padding: 15px 10px 20px 10px;
  margin-bottom: 35px;
}

.sidebar--image {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.sidebar--image.collapse {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.sidebar--requests {
  display: flex;
  align-items: center;

  @media #{layout.$breakpoint-s} {
    justify-content: space-between;
    padding-right: 12px;
    padding-left: 10px;
  }
}

.requests--arrow--wrapper {
  visibility: hidden;

  svg path {
    fill: colors.$color--white;
  }

  &.appear {
    visibility: visible;
    width: 40px;
    height: 40px;
    @include mixins.flexbox ($align: center, $justify: space-around);
  }

  &.active {
    svg path {
      fill: colors.$color--white;
    }
  }

  &.in-active {
    svg path {
      fill: colors.$color--friar-grey;
      transition: fill 0.7s ease-in-out;
    }
  }
}

.requests--arrow {
  &.open {
    transform: rotate(180deg);
  }
}

.sidebar-item-title {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, width 0.5s ease-in-out;
}

.sidebar-item-title.appear {
  opacity: 1;
  visibility: visible;
}

.sidebar-my-profile-container {
  min-height: 48px;
  @include mixins.flexbox($align: center, $justify: space-between);
  white-space: nowrap;
  padding-left: 8px;

  &.active {
    background-color: colors.$color--radical-red;
    color: colors.$color--white;
    border-radius: 8px;
  }

  @media #{layout.$breakpoint-s} {
    justify-content: space-between;
    padding-right: 12px;
    padding-left: 10px;
  }
}

.sidebar-additional-items {
  @include mixins.flexbox();
}

@use '../../../assets/sass/settings/config';
@use '../settings/variables/colors';
@use '../settings/variables/layout';
@use '../settings/mixins/mixins';
@use '../settings/variables/icons';
@use '../settings/variables/typography';


.form__item--search {

  .form--search-triggered {
    border-width: 0;
    max-width: 0;
    padding: {
      left: 0;
      right: 0;
    }
  }

  .search-placeholder {
    opacity: 0;
  }

  .search-results {
    max-height: 0;
  }

  &.open {
    z-index: 5;

    .form--search-triggered {
      padding: {
        left: 102px;
        right: 8px;
      }

      @media #{layout.$breakpoint-m-max} {
        max-width: 100%;
        right: 15px;
      }

      @media #{layout.$breakpoint-l} {
        max-width: 465px;
        width: 100%;
      }
    }

    .search-placeholder {
      opacity: 1;
      transition: all .3s ease;
    }

    .search-results {
      min-height: 100%;
      padding: 20px 15px;
    }
  }
}

.form--search {
  @include mixins.fontStyle ($fontSize: 14px);
  font-family: typography.$font-primary;
  background: {
    color: colors.$color--pampas;
    image: url(icons.$icon--search);
    position: left 5px center;
    repeat: no-repeat;
  }
  border: 1px solid colors.$color--pampas;
  border-radius: 4px;
  padding: {
    bottom: 0;
    top: 0;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: colors.$color--friar-grey;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    color: colors.$color--friar-grey;
  }

  &:-ms-input-placeholder { /* IE 10+ */
    color: colors.$color--friar-grey;
  }

  &:-moz-placeholder { /* Firefox 18- */
    color: colors.$color--friar-grey;
  }

  &::-webkit-search-cancel-button {
    display: none;
  }

  &:focus {
    background-color: colors.$color--white;
    border-color: colors.$color--white;
    outline: none;
  }

  @media #{layout.$breakpoint-l} {
    &:hover {
      border-color: colors.$color--cloud;
    }
  }

  &-open {
    @include mixins.fontStyle ($lineHeight: 32px, $fontColor: colors.$color--friar-grey);
    padding: {
      left: 36px;
      right: 8px;
    }

    @media #{layout.$breakpoint-s} {
      width: 160px;
    }
  }

  &-triggered {
    @include mixins.fontStyle ($lineHeight: 34px, $fontWeight: typography.$fw--medium, $fontColor: colors.$color--dune);
    position: absolute;
    transition: all .3s ease-in-out;

    @media #{layout.$breakpoint-m-max} {
      left: 15px;
      top: 13px;
    }

    @media #{layout.$breakpoint-l} {
      right: 10px;
      top: 0;
    }
  }

  &-small {

    @media #{layout.$breakpoint-m-min} {
      width: 150px;
    }
  }

  &-big {

    @media #{layout.$breakpoint-m-min} {
      width: 465px;
    }
  }
}

.search-trigger {
  background: {
    color: colors.$color--pampas;
    image: url(icons.$icon--search);
    position: center center;
    repeat: no-repeat;
  }
  border-radius: 4px;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 34px;
  margin-right: 10px;
  width: 34px;

  @media #{layout.$breakpoint-l} {
    margin-left: 10px;
  }
}

.search-placeholder {
  @include mixins.fontStyle ($fontSize: 14px, $lineHeight: 34px, $fontColor: colors.$color--friar-grey);
  pointer-events: none;
  position: absolute;

  @media #{layout.$breakpoint-m-max} {
    left: 48px;
    top: 14px;
  }

  @media #{layout.$breakpoint-l} {
    right: 375px;
    top: 1px;
  }
}

.search-results {
  background-color: colors.$color--white;
  border-radius: 7px;
  overflow: hidden;
  position: absolute;
  transition: all .3s ease-in-out;

  display: none;

  @media #{layout.$breakpoint-m-max} {
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05), 0px 5px 20px rgba(0, 0, 0, 0.05);
    left: 15px;
    right: 15px;
    top: calc(100% + 8px);
  }

  @media #{layout.$breakpoint-l} {
    right: -125px;
    top: calc(100% + 20px);
    width: 600px;
  }

  & > p {
    font-weight: typography.$fw--medium;
  }
}

@use '../settings/mixins/mixins';
@use '../settings/variables/layout';
@use '../settings/variables/colors';
@use '../settings/variables/typography';
@use '../settings/variables/icons';

button {
  font-family: typography.$font-primary;
}

.button {
  @include mixins.fontStyle($fontSize: 14px, $fontWeight: typography.$fw--bold);
  @include mixins.flexbox($justify: center);

  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  text-align: center;
  text-decoration: none;
  transition: all .1s ease;

  &--big {
    line-height: 43px;
    padding-right: 24px;

    &:not(.button--icon) {
      padding-left: 24px;
    }
  }

  &--small {
    line-height: 32px;
    padding-right: 17px;

    &:not(.button--icon) {
      padding-left: 17px;
    }
  }

  &--tiny {
    font-size: 11px;
    line-height: 22px;
    padding-right: 12px;

    &:not(.button--icon) {
      padding-left: 12px;
    }
  }

  &--label--extend {
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  &--time--record {
    @media #{layout.$breakpoint-s} {
      margin-bottom: 10px;
    }
  }

  &--red {
    background-color: colors.$color--radical-red;
    border-color: colors.$color--radical-red;
    color: colors.$color--white;
    margin-right: 0px;

    &:hover {
      background-color: colors.$color--amaranth;
      border-color: colors.$color--amaranth;
    }
  }

  &--outline {
    background-color: colors.$color--white;
    color: colors.$color--dune;

    &:not(:hover) {
      border-color: colors.$color--cararra;
    }

    &:hover {
      border-color: colors.$color--cloud;
    }
  }

  &--red-outline {
    color: colors.$color--radical-red;

    &:not(:hover) {
      background-color: colors.$color--white;
      border-color: colors.$color--radical-red;
    }

    &:hover {
      background-color: colors.$color--pippin;
      border-color: colors.$color--pippin;
    }
  }

  &--margin--left {
    margin-left: 15px;
  }

  &--simple {
    border-width: 0;
    color: colors.$color--dune;
  }

  &--disabled {
    pointer-events: none;
    opacity: 20%;
  }

  &:not(.button--red):not(.button--simple) {
    box-shadow: 0 1px 2px 0 #00000011;
  }

  &--icon {
    background-repeat: no-repeat;

    @media #{layout.$breakpoint-s} {
      background-position: center center;
      flex: 0 0 34px;
      font-size: 0;
      height: 34px;
      padding: 0;
      width: 34px;
    }

    @media #{layout.$breakpoint-m-min} {
      background-position: left 8px center;
      padding-left: 40px;
    }
  }

  &--icon-only {
    background: {
      position: center center;
      repeat: no-repeat;
    }
    font-size: 0;
    margin-left: 10px;
    padding: 0;

    &.button--small {
      flex: 0 0 24px;
      height: 24px;
      width: 24px;
    }

    &.button--mid {
      flex: 0 0 34px;
      height: 34px;
      width: 34px;
    }

    &.button--big {
      flex: 0 0 45px;
      height: 45px;
      width: 45px;
    }

    &.button--large {
      @media #{layout.$breakpoint-m-max} {
        flex: 0 0 34px;
        height: 34px;
        width: 34px;
      }

      @media #{layout.$breakpoint-l} {
        flex: 0 0 68px;
        height: 68px;
        width: 68px;
      }
    }
  }

  &--dropdown {
    padding-right: 30px;
    position: relative;

    &:after {
      background: {
        image: url(icons.$icon--select-list);
        repeat: no-repeat;
        size: contain;
      }
      content: '';
      height: 4px;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      width: 7px
    }

    &.button--icon:after {
      @media #{layout.$breakpoint-s} {
        display: none;
      }
    }
  }

  // Icons for buttons.
  &--add-member {
    background-image: url(icons.$icon--member--add);
  }

  &--client-company-add {
    background-image: url(icons.$icon--client-company--add);
  }

  &--edit {
    background-image: url(icons.$icon--edit);
  }

  &--check {
    background-image: url(icons.$icon--check);
  }

  &--delete {
    background-image: url(icons.$icon--delete);
  }

  &--settings {
    background-image: url(icons.$icon--settings);
  }

  &--settings-red {
    background-image: url(icons.$icon--settings--red);
  }

  &--filters {
    background-image: url(icons.$icon--filters);

    &.is-active {
      @media #{layout.$breakpoint-s} {
        background: {
          color: colors.$color--radical-red;
          image: url(icons.$icon--filters-active)
        }
        border-color: colors.$color--radical-red;
      }
    }
  }

  &--dropdown {

    &[data-type="list-view"] {
      background-image: url(icons.$icon--view--list);
    }

    &[data-type="kanban-view"] {
      background-image: url(icons.$icon--view--kanban);
    }

    &[data-type="calendar-view"] {
      background-image: url(icons.$icon--view--calendar);
    }
  }

  &--close {
    background-image: url(icons.$icon--cancel);
  }

  &--modal-close--primary {
    background-image: url(icons.$icon--cancel--white);
  }

  &--modal-close--secondary {
    background: {
      color: colors.$color--pampas;
      image: url(icons.$icon--cancel);
    }
    border: {
      color: colors.$color--pampas;
      radius: 4px;
    }

    @media #{layout.$breakpoint-l} {
      &:hover {
        background-color: colors.$color--cararra;
      }
    }
  }

  &--watch {
    background: {
      color: colors.$color--pampas;
      image: url(icons.$icon--watch);
    }
    border-color: colors.$color--pampas;

    @media #{layout.$breakpoint-l} {
      &:hover {
        background-color: colors.$color--cararra;
      }
    }

    &.is-active {
      background: {
        color: colors.$color--radical-red;
        image: url(icons.$icon--watch--active);
      }
      border-color: colors.$color--radical-red;

      @media #{layout.$breakpoint-l} {
        &:hover {
          background-color: colors.$color--amaranth;
        }
      }
    }
  }

  &--collapse {
    background: {
      position: center center;
      repeat: no-repeat;
    }
    flex: 0 0 24px;
    font-size: 0;
    height: 24px;
    transition: all .2s ease;
    width: 24px;

    &:not(.collapsed) {
      background-image: url(icons.$icon--chevron--grey--down);
    }

    &.collapsed {
      background-image: url(icons.$icon--chevron--grey--up);
    }
  }

  &--reply {
    background-image: url(icons.$icon--reply);
  }

  &--internal {
    background-image: url(icons.$icon--private);
  }

  &--back {
    background-image: url(icons.$icon--chevron--grey--left);
    padding-left: 24px;
  }

  &--radio {
    background-image: url(icons.$icon--input--radio);
  }

  &--select {
    background-image: url(icons.$icon--chevron--grey--down);
  }

  &--text {
    background-image: url(icons.$icon--input--text);
  }

  &--multiline {
    background-image: url(icons.$icon--input--text-multiline);
  }

  &--file {
    background-image: url(icons.$icon--input--attach);
  }

  &--checkbox {
    background-image: url(icons.$icon--input--checkbox);
  }

  &--separator {
    background-image: url(icons.$icon--input--separator);
  }

  &--date {
    background-image: url(icons.$icon--view--calendar--grey);
  }

  &--preview {
    background-image: url(icons.$icon--preview);
  }

  &--group {
    @include mixins.flexbox($justify: flex-start);
    width: 100%;
    margin-top: 15px;
  }
}

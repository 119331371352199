@use '../../assets/sass/settings/mixins/mixins';

#change-password-wrapper {
  &.page--form {
    @include mixins.flexbox ($align: center, $justify: center);

    .align-buttons {
      @include mixins.flexbox ($justify: flex-end);

      margin-top: 20px;

      .form--submit {
        flex: 0 0 30% !important;
        @include mixins.flexbox ($justify: center);
      }
    }
  }
}

.form--forgot-password {
  .button--big {
    @include mixins.flexbox ($justify: center);
  }
}

@use 'src/assets/sass/settings/variables/layout';

.circle {
  display: block;
  border: .15rem solid #e9e9e9;
  border-top: .15rem solid #fe455a;
  border-radius: 50%;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;

  &--large {
    width: 100px;
    height: 100px;
  }

  &--small {
    width: 24px;
    height: 24px;
  }
}

.container-small {
  position: relative;
  width: 24px;
  height: 24px;
}

.container-large {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 45%;
  left: 45%;

  @media #{layout.$breakpoint-s} {
    left: 39.5%;
  }
}

// Font faces.
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../../../fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
    url('../../../fonts/roboto-v20-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../fonts/roboto-v20-latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../../../fonts/roboto-v20-latin-300.woff')
      format('woff'),
    /* Modern Browsers */ url('../../../fonts/roboto-v20-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../fonts/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../../../fonts/roboto-v20-latin-400.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
    url('../../../fonts/roboto-v20-latin-400.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../fonts/roboto-v20-latin-400.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../../../fonts/roboto-v20-latin-400.woff')
      format('woff'),
    /* Modern Browsers */ url('../../../fonts/roboto-v20-latin-400.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../fonts/roboto-v20-latin-400.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../../../fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('../../../fonts/roboto-v20-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../fonts/roboto-v20-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../../../fonts/roboto-v20-latin-500.woff')
      format('woff'),
    /* Modern Browsers */ url('../../../fonts/roboto-v20-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../fonts/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../../../fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('../../../fonts/roboto-v20-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../fonts/roboto-v20-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../../../fonts/roboto-v20-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('../../../fonts/roboto-v20-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

// Fonts.
$font-primary: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Cantarell', 'Roboto', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica',
  'Helvetica Neue', 'Arial', sans-serif;

// Font weights.
$fw--normal: 400;
$fw--medium: 500;
$fw--bold: 700;

// Font sizes.
$fs--root: 100%;

@use '../settings/variables/colors';
@use '../settings/variables/layout';
@use '../settings/mixins/mixins';
@use '../settings/variables/icons';
@use '../settings/variables/typography';

.dropdown {
  position: relative;
  cursor: pointer;

  &:not(.open) {
    ul {
      height: 0;
      visibility: hidden;
    }
  }

  &.open {
    ul {
      max-height: 50vh;
      overflow-y: auto;
      visibility: visible;
      z-index: 2;

      &.fixed {
        position: fixed;

        @media #{layout.$breakpoint-s} {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .disabled {
    background-color: colors.$color--cararra;
    border-color: colors.$color--cloud;
    color: colors.$color--friar-grey;
  }

  &__label,
  &__value {
    pointer-events: none;
  }

  &__label.bold > span {
    @include mixins.fontStyle ($fontWeight: typography.$fw--bold, $fontColor: colors.$color--dune);
  }

  &__list {
    background-color: colors.$color--white;
    border-radius: 7px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05), 0 5px 20px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
    z-index: 1;

    //&--filters {
    //  @media #{layout.$breakpoint-s} {
    //    bottom: 35px;
    //    z-index: 10;
    //    top: initial;
    //  }
    //}

    &--canned {
      @media #{layout.$breakpoint-m-max} {
        bottom: 35px;
        z-index: 10;
        top: initial;
      }
    }
  }

  &--selected {
    border: solid 1px red;
    border-radius: 5px;
  }

  &__item {
    .align__fix {
      width: 100%;
      @include mixins.flexbox($align: center);
    }
  }

  &__item,
  &__link {
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;

    &:not(:last-of-type) {
      @include mixins.border ($side: bottom);
    }

    @media #{layout.$breakpoint-l} {
      &:hover {
        background-color: colors.$color--pampas;
      }
    }
  }

  .form--search {
    margin: 10px;
  }

  &--basic {
    .dropdown__item,
    .dropdown__link {
      @include mixins.fontStyle ($fontSize: 13px, $fontWeight: typography.$fw--medium, $lineHeight: 40px, $fontColor: colors.$color--friar-grey);
      display: -webkit-box;
      padding: {
        left: 15px;
        right: 15px;
      }

      @media #{layout.$breakpoint-l} {
        &:hover {
          color: colors.$color--radical-red;
        }
      }
    }

    .dropdown__link {
      margin: {
        left: -15px;
        right: -15px;
      }
    }
  }

  &--filter {
    .dropdown__value {
      @include mixins.fontStyle ($fontWeight: typography.$fw--normal, $fontColor: colors.$color--dusty-gray);
    }

    .dropdown__item {
      padding: {
        left: 10px;
        right: 10px;
      }
    }
  }

  &--view {
    @media #{layout.$breakpoint-s} {
      &.open {
        .dropdown__list {
          position: absolute;
          left: 0;
          right: inherit !important;
        }
      }
    }
    .dropdown__link {
      span {
        background: {
          position: left 12px center;
          repeat: no-repeat;
        }
        padding-left: 40px;
        margin-left: -10px;
        display: block;

        &[data-type='list-view'] {
          background-image: url(icons.$icon--view--list--grey);
        }

        &[data-type='kanban-view'] {
          background-image: url(icons.$icon--view--kanban--grey);
        }

        &[data-type='calendar-view'] {
          background-image: url(icons.$icon--view--calendar--grey);
        }

        @media #{layout.$breakpoint-l} {
          &:hover {
            &[data-type='list-view'] {
              background-image: url(icons.$icon--view--list);
            }

            &[data-type='kanban-view'] {
              background-image: url(icons.$icon--view--kanban);
            }

            &[data-type='calendar-view'] {
              background-image: url(icons.$icon--view--calendar);
            }
          }
        }
      }
    }
  }

  &--status {
    .dropdown__item {
      @include mixins.flexbox ($align: center);
      @include mixins.fontStyle ($fontSize: 13px, $fontWeight: typography.$fw--medium, $fontColor: colors.$color--black);
      padding: {
        bottom: 10px;
        top: 10px;
      }
    }

    .dropdown__link {
      @include mixins.fontStyle ($fontSize: 11px, $fontWeight: typography.$fw--bold);
      margin-left: -10px;
      border-radius: 10px;
      padding: 4px 10px 3px;
      text-transform: uppercase;

      &[data-type='new'] {
        background-color: colors.$color--peach-yellow;
        color: colors.$color--indochine;
      }

      &[data-type='in progress'] {
        background-color: rgba(colors.$color--apple, 0.3);
        color: colors.$color--la-palma;
      }

      &[data-type='finished'] {
        background-color: colors.$color--moon-raker;
        color: colors.$color--heliotrope;
      }

      &[data-type='closed'] {
        background-color: colors.$color--mandys-pink;
        color: colors.$color--pomegranate;
      }
    }
  }

  &--assignee {
    .dropdown__item {
      @include mixins.flexbox ($align: center);
      @include mixins.fontStyle ($fontSize: 13px, $fontWeight: typography.$fw--medium, $fontColor: colors.$color--black);
      padding: {
        bottom: 5px;
        top: 5px;
      }

      &:nth-child(3) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    &--higlight {
      .dropdown__item {
        &:nth-child(2) {
          background-color: colors.$color--radical-red;
          color: colors.$color--white;
          padding: {
            bottom: 10px;
            top: 10px;
          }
          .dropdown__link:hover {
            background-color: colors.$color--radical-red;
          }
        }
      }
    }

    img {
      border-radius: 50%;
      height: 27px;
      margin-right: 10px;
      width: 27px;
    }
  }

  &--date {
    position: relative;

    .dropdown__value {
      display: inline-flex;
      width: 65px;
    }

    input[type='date'] {
      inset: 0;
      opacity: 0;
      position: absolute;
      width: 100%;

      &::-webkit-calendar-picker-indicator {
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 10;
      }
    }
  }

  .table &,
  .record & {
    height: 20px;
    width: 20px;

    span {
      background: {
        image: url(icons.$icon--table-options);
        position: center center;
        repeat: no-repeat;
      }
      cursor: pointer;
      display: block;
      font-size: 0;
      height: 100%;
      position: relative;
      width: 100%;
    }
  }

  .three-dots {
    pointer-events: all;
    cursor: pointer;
  }

  .hideSpan {
    z-index: 0;
    pointer-events: none;
  }
}

// Layout size list
$l-max-width:     1200px;
$l-content-width: 1030px;

$header-width-m-max:  1260px;
$comments-width-m-max: 1060px;

// Breakpoint list.
//
// Note *
// The value of the key MUST be a string!
$breakpoints: (
  s-min:  '(max-width: 576px)',                           // Resolutions lower than 576px.
  s:      '(max-width: 767px)',                           // Resolutions lower than 767px.
  m-min:  '(min-width: 768px)',                           // Resolutions bigger than 768px.
  m:      '(min-width: 768px) and (max-width: 1023px)',   // Resolutions between 768px and 1023px.
  m-max:  '(max-width: 1023px)',                          // Resolutions lower than 1023px.
  tab-l:  '(min-width: 768px) and (max-width: 1280px)',   // Resolutions between 768px and 1280px.
  l:      '(min-width: 1024px)'                           // Resolutions bigger than 1024px.
);

$breakpoint-s-min:  map-get($breakpoints, s-min);
$breakpoint-s:      map-get($breakpoints, s);
$breakpoint-m:      map-get($breakpoints, m);
$breakpoint-m-min:  map-get($breakpoints, m-min);
$breakpoint-m-max:  map-get($breakpoints, m-max);
$breakpoint-tab-l:  map-get($breakpoints, tab-l);
$breakpoint-l:      map-get($breakpoints, l);

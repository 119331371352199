@use '../settings/variables/colors';
@use '../settings/variables/typography';
@use '../settings/variables/layout';
// Scroll disabled if class is present.
.u-no-scroll {
  overflow: hidden;
}

// Search overlay.
.u-search-overlay {
  position: fixed;
  inset: 0;
  z-index: 100;
  background-color: colors.$color--ash-white;
}

// Clearfix
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.padding {
  &--bottom {
    padding-bottom: 10px;
  }

  &--top {
    padding-top: 10px;
  }

  &--left {
    padding-left: 10px;
  }

  &--right {
    padding-right: 10px;
  }
}

.margin {
  &--top {
    margin-top: 10px;
  }

  &--bottom {
    margin-bottom: 10px;
  }

  &--right {
    margin-right: 10px;
  }

  &--left {
    margin-left: 10px;
  }

  &--top-bottom {
    margin: 25px 0;
  }
}

.flex {
  display: flex;

  &--column {
    flex-direction: column;
  }

  &--justify--end {
    justify-content: end;
  }

  &--justify--center {
    justify-content: center;
  }

  &--justify--space-between {
    justify-content: space-between;
  }

  &--column {
    flex-direction: column;
  }

  &--column--mobile {
    @media #{layout.$breakpoint-s} {
      flex-direction: column;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &--align--center {
    align-items: center;
  }

  &--align-start {
    align-items: flex-start;
  }
}

.max-width {
  width: 100%;
}

.middle-width {
  width: 50%;
}

.hide {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  cursor: default;

  &--bgColor {
    opacity: .5;
  }
}

.font-weight {
  &--tiny {
    font-weight: 300;
  }

  &--normal {
    font-weight: typography.$fw--normal;
  }

  &--medium {
    font-weight: typography.$fw--medium;
  }

  &--bold {
    font-weight: typography.$fw--bold;
  }
}

.font-size {
  &--large {
    font-size: 60px;
  }
}

.center-text-mobile {
  @media #{layout.$breakpoint-m-max} {
    text-align: center;
  }
}

.text-align {
  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }
}

.background {
  &--color-gallery {
    @media #{layout.$breakpoint-m-max} {
      background-color: colors.$color--gallery;
    }
  }
}

.image-medium-size {
  @media #{layout.$breakpoint-l} {
    width: 25%;
    height: 25%;
  }

  @media #{layout.$breakpoint-m} {
    width: 40%;
    height: 40%;
  }

  width: 60%;
  height: 60%;
}

.shrink {
  @media #{layout.$breakpoint-l} {
    margin-left: 200px;
  }
}

.word-break {
  &--initial {
    word-break: initial;
  }

  &--break {
    word-break: break-word;
  }
}

.list-padding {
   ol, ul {
    padding-left: 16px;
  }
}

.mobile-toolbar {
    @media #{layout.$breakpoint-m-max} {
      padding-bottom: 56px;
    }
}

.display {
  &--inline-block {
    display: inline-block;
  }

  &--inline-flex {
    display: inline-flex;
  }
}

.mobile-height {
  &--max {
    @media #{layout.$breakpoint-m-max} {
      height: 100%;
    }
  }
}

.gradiant-overflow {
  position: relative;
  &:after {
    content: "";
    width: 35px;
    height: 21px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
  }
}

.sidebar--margin {
  @media #{layout.$breakpoint-l} {
    margin-left: 80px;
  }
}

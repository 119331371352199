// Color variables.
$color--white: #ffffff;
$color--red: #ff0000;
$color--black: #000000;
$color--ashy: #cccccc;
$color--lightgray: #D3D3D3;

$color--pampas: #f7f6f3;
$color--gallery: #ededed;
$color--cararra: #e8e7e4;
$color--quill-gray: #dbdad7;
$color--alto: #d8d8d8;
$color--cloud: #cbc9c4;
$color--dusty-gray: #9b9b9b;
$color--gray: #8c8c8c;
$color--friar-grey: #7c7b76;
$color--dove-gray: #737373;
$color--dune: #373530;
$color--dirty-white: #f5f5f5;
$color--ash-white: #f4f4f4;
$color--darker-gray: #3b4151;
$color--black-russian: #00000011;

$color--pristine-ivory: #f7f5f3;
$color--pippin: #ffe4e7;
$color--radical-red: #fe455a;
$color--pomegranate: #ef3736;
$color--amaranth: #e33e51;
$color-light-red: #ee4052;
$color--maroon-flush: #bf1650;
$color--raspberry-red: #ff455a;
$color--coral-pink: #ff7383;

$color--apple: #34c635;
$color--la-palma: #19941a;
$color--indochine: #c96e00;
$color--heliotrope: #886cff;

//Label picker colors
$color--label-green: #c2eec3;
$color--mandys-pink: #f4bcba;
$color--peach-yellow: #f9d8af;
$color--moon-raker: #d6ccf6;
$color--label-sky: #cce4f6;
$color--label-purple: #f2cde4;
$color--label-border: #e98d9a;

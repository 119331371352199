@use '../../../assets/sass/settings/config';
@use '../../../assets/sass/settings/variables/colors';

.header-logo {
  &:focus:not(:active) {
    border: 0;
    outline: none;
    border-bottom: 3px solid colors.$color--radical-red;
  }
}

.header-overlay-container {
  position: relative;
}

.header-overlay-element {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.header-overlay-element.active {
  opacity: 1;
  transition: opacity 0.8s ease-in-out;
}

.header-overlay-element img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

@use '../../../assets/sass/settings/config';
@use '../../../assets/sass/components/nav-main';
@use '../../../assets/sass/settings/variables/layout';
@use '../../../assets/sass/settings/mixins/mixins';

.hide--desktop {
    @media #{layout.$breakpoint-l} {
        display: none;
    }

    @media #{layout.$breakpoint-m-max} {
        &.header--sidebar {
            @include mixins.flexbox($align: flex-start, $direction: column);
            padding-left: 15px;
        }
    }
}



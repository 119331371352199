@use '../settings/mixins/mixins';
@use '../settings/variables/layout';
@use '../settings/variables/colors';

.page--form {
  margin: auto;
  overflow: initial;

  @include mixins.flexbox();

  @media #{layout.$breakpoint-m-max} {
    background-color: colors.$color--white;
  }

  @media #{layout.$breakpoint-s-min} {
    min-height: 100%;
  }

  .region__inner {
    padding: 25px 28px !important;
  }

  .button {
    @media #{layout.$breakpoint-m-max} {
      flex: 0 1 50%;
      justify-content: center;
    }
  }
}

.region--content--center {
  @include mixins.flexbox ($justify: center, $align: center);

  @media #{layout.$breakpoint-l} {
    padding: {
      bottom: 50px;
      top: 50px;
    }
  }

  @media #{layout.$breakpoint-s-min} {
    @include mixins.flexbox ($justify: center, $align: flex-start);
  }

  .region__inner {
    border-radius: 7px;
    @media #{layout.$breakpoint-m-max} {
      flex: 0 0 320px;
      padding: 15px;
    }

    @media #{layout.$breakpoint-l} {
      background-color: colors.$color--white;
      flex: 0 0 450px;
      padding: 25px 30px 30px;
    }
  }
}

.region--content--2-col {
  .page--auth {
    @media #{layout.$breakpoint-m-max} {
      @include mixins.flexbox($justify: center);
      width: 100%;
    }
  }
  .content--left {
    @media #{layout.$breakpoint-m-max} {
      width: 350px;
      max-width: 100%;
      padding: 10px 15px 30px;
    }

    @media #{layout.$breakpoint-l} {
      background-color: colors.$color--white;
      flex: 0 0 540px;
      padding: 75px;
    }
  }

  .content--right {
    @media #{layout.$breakpoint-l} {
      @include mixins.flexbox ($align: center, $justify: center);
      flex: 1;
    }
  }

  .form-header {
    @include mixins.flexbox ($align: center, $justify: space-between);
  }
}
